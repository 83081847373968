import * as React from "react"
import {useEffect, useState } from "react"
import CoreImg from "../images/screen-core.png"
import UpgradeImg from "../images/screen-upgrade.png"
import CombatImg from "../images/screen-combat.png"
import CurseImg from "../images/screen-curse.png"
import InventoryImg from "../images/screen-inventory.png"
import VesselImg from "../images/screen-vessel.png"
import Analytics from "../utils/analytics"

const defaultImages = [
  { src: CoreImg, alt: "core-and-combat-overview"}, 
  { src: UpgradeImg, alt: "powerful-upgrade-in-action"},
  { src: CombatImg, alt: "tower-combat-action"}, 
  { src: CurseImg, alt: "player-picks-curse-to-dispell"},
  { src: InventoryImg, alt: "player-inventory-showcase"},
  { src: VesselImg, alt: "vessel-beauty-shot"}
];

const Slider = () => {
  const [images, setImages] = useState(defaultImages);
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    setImages(defaultImages.map((_, i) => defaultImages[(i + imgIndex) % defaultImages.length]));
  }, [imgIndex]);

  function selectorClick(i: number,  e: React.MouseEvent<HTMLButtonElement>) {
    var index = (i + images.length) % images.length;
    setImgIndex(index);
    Analytics.sendEventObject({
      event: "browse_carousel",
      imageIndex: index,
      imageCount: images.length,
      imageAlt: images[index].alt
    });
  }

  return (
    <div className="slider section section--slider">
      <div className="slider__images row row--center">
        {images.map((img, i) => (
          <div key={`slider-${i}`} className={"slider__image " + (i == 0 
              ? "slider__image--shadow"
              : "slider__image--inactive slider__image--" + (i == 1 ? "right" : "left"))}>
                {i != 0 && (
                  <>
                    <div className="media__overlay"></div>
                    <div className={"slider__image-button-container slider__image-button-container--" + (i == 1 ? "right" : "left")}>
                      <button className="slider__image-button" onClick={e => selectorClick((i == 1 ? imgIndex + 1 : imgIndex - 1), e)}>
                      </button>
                      <div className="slider__image-line"></div>
                    </div>
                  </> )}
                <img src={img.src} alt={img.alt}></img>
          </div>
        ))}
      </div>
      <div className="slider__selector row row--center">
          <div className="desktop-hidden">
            <div className="slider__image-button-container slider__image-button-container--left">
              <button className="slider__image-button" onClick={e => selectorClick(imgIndex - 1, e)}>
              </button>
              <div className="slider__image-line"></div>
            </div>
          </div>
          <div className="slider__selector-icons">
            <div className="row row--center">
              {images.map((_, i) => 
                <button key={`slider-selector-${i}`} className={"slider__selector-icon " + (imgIndex == i ? "slider__selector-icon--active" : "")} onClick={e => selectorClick(i, e)}>
                </button>
              )}
            </div>
          </div>
          <div className="desktop-hidden">
            <div className="slider__image-button-container slider__image-button-container--right">
              <button className="slider__image-button" onClick={e => selectorClick(imgIndex + 1, e)}>
              </button>
              <div className="slider__image-line"></div>
            </div>
          </div>
      </div>
    </div>
  )
  
};

export default Slider;