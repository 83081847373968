import "../css/index.sass"
import * as React from "react"

import Header from "../components/header"
import Details from "../components/details"
import Slider from "../components/slider"
import Footer from "../components/footer"
import HeaderActions from "../components/headerActions"
import SEO from "../components/seo"
import AnalyticsConfirm from "../components/analyticsConfirm"
import Trailer from "../components/trailer"


const IndexPage = () => {
  return (
    <main>
      COUCOU
      <Header />
      <HeaderActions />
      <Trailer />
      <Details />
      <Slider />
      {/* <AnalyticsConfirm /> */}
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)