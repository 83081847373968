import * as React from "react"
import DescriptionImg from "../images/tot_hero.png"
import DescriptionBgImg from "../images/description_background.png"


const Details = () => (
  <div className="details section section--details row">
    <div className="details__description flex">
      <p className="details__heading text text--gold">
        In Towers of Thana, defend various mythologies against a dark corruption that wants to swallow them whole. Travel to several mythological homelands and rally them to your cause against Ogur, a God turned evil, and his corruption.
      </p>
      <p className="details__text text bullet-point">
        Analyze the Map to establish your strategy and prepare for battle.
      </p>
      <p className="details__text text bullet-point">
        Complete different missions to rescue the inhabitants of Thana.
      </p>
      <p className="details__text text bullet-point">
        Summon towers and invoke magic on the battlefield to push back the corruption.
      </p>
      <p className="details__text text bullet-point">
        Manage the war effort and develop your headquarters throughout your adventure.
      </p>
    </div>
    <div className="details__img-container flex">
      <img className="details__img-background" src={DescriptionBgImg}></img>
      <img className="details__img" src={DescriptionImg} alt="hero-concept-art"></img>
    </div>
  </div>
);

export default Details;