class UniqueCallback
{
  public callback?: ()=>void;
  public sent: boolean;

  constructor(callback?: ()=>void) {
    this.callback = callback;
    this.sent = false;
  }

  public send() {
    if (this.sent || this.callback == null)
      return;

    this.callback();
    this.sent = true;
  }

  public hasCallback(): boolean {
    return this.callback != null;
  }
}

export default class Analytics {

  private static TIMEOUT: number = 2000;

  public static redirectEventString = function (event: string, destination: string, onDone?: ()=>void) {
    Analytics.redirectEventObject({
      event: event
    }, destination, onDone);
  }
  
  public static redirectEventObject = function (event: DataEvent, destination: string, onDone?: ()=>void) {
    if (!Analytics.isBrowser()) {
      if (onDone != null)
        onDone();
      return;
    }
    
    //Basically starting a timeout and an dataLayer event. Whichever finishes first gets to finish this task,
    //wish will call the ballback (only once if both eventually finish) and redirect the browser URL.
    //We need the timeout because of ad blocks and other possible problems
    var uniqueCallback: UniqueCallback = new UniqueCallback(onDone);
    setTimeout(() => Analytics.finish(uniqueCallback, destination), Analytics.TIMEOUT);
    window.dataLayer?.push(event, () => Analytics.finish(uniqueCallback, destination));
  }

  private static finish = function (uniqueCallback: UniqueCallback, destination: string) {
    uniqueCallback.send();
    window.location.href = destination;
  }

  public static sendEventString = function (event: string) {
    Analytics.sendEventObject({event: event});
  }

  public static sendEventObject = function (event: DataEvent) {
    window.dataLayer?.push(event);
  }

  private static isBrowser = function(): boolean {
    return typeof window !== "undefined";
  }
}