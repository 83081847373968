import * as React from "react"
import VideoBorderImg from "../images/trailer_border.svg"

const Trailer = () => (
  <div>
    <div className="trailer section section--trailer row row--center">
      <div className="trailer__background"></div>
      <div className="video">
        <img className="video__border video__border--left" src={VideoBorderImg}></img>
        {/* Disabling blue button thingy. Youtube's button does the job
        <div className="media__overlay column column--center">
          <div className="video__icon"></div>
          <span className="video__text text text--gold">Game trailer</span>
        </div> */}
        <iframe src="https://www.youtube.com/embed/7awyIWiqHDo?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope" allowFullScreen></iframe>
        <img className="video__border video__border--right" src={VideoBorderImg}></img>
      </div>
    </div>
  </div>
);

export default Trailer;